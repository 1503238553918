export function closeModal (modal) {
  if (typeof modal.close === 'function') {
    modal.close()
  }

  modal.setAttribute('hidden', 'hidden')
}

export function closeModalOnClick (closeButton) {
  closeButton.addEventListener('click', function () {
    const modal = this.closest('[data-js-modal]')
    closeModal(modal)
  })
}

export function showModal (modal) {
  if (typeof modal.showModal === 'function' && modal.open === false) {
    modal.showModal()
  }

  modal.removeAttribute('hidden')
}

export function initModal (modalButtons) {
  modalButtons.forEach(btn => {
    const btnControls = btn.getAttribute('aria-controls')
    const btnModal = document.querySelector('#' + btnControls)

    btn.addEventListener('click', function (event) {
      event.preventDefault()
      showModal(btnModal)

      document.addEventListener('click', function (event) {
        if (event.target.getAttribute('aria-controls') !== btnControls &&
            btnModal.open && !event.target.closest('.dialog__content')) {
          closeModal(btnModal)
        }
      })
    })
  }, false)
}

const modalCloseButton = document.querySelectorAll('[data-js-modal-close]')
modalCloseButton.forEach(button => {
  closeModalOnClick(button)
})

const modalButtons = document.querySelectorAll('[data-js-open-modal]')
initModal(modalButtons)
