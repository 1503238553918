import { createPopper } from '@popperjs/core'

const popperInstances = []

export function initPopover (popoverButtons) {
  popoverButtons.forEach(btn => {
    const btnControls = btn.getAttribute('aria-controls')
    const btnPopoverSelector = document.querySelector('#' + btnControls)

    popperInstances.push(createPopper(
      btn,
      btnPopoverSelector,
      {
        placement: 'right-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-10, -5]
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: [
                'right',
                'right-start',
                'left-end',
                'left',
                'left-start',
                'left-end',
                'top-start',
                'top',
                'top-end',
                'bottom-start',
                'bottom',
                'bottom-end'
              ]
            }
          }
        ]
      }
    ))

    const id = popperInstances.length - 1

    btn.addEventListener('click', function (event) {
      event.preventDefault()
      btnPopoverSelector.classList.add('is--expanded')

      popperInstances[id].update()

      document.addEventListener('click', function (event) {
        if (event.target !== btn && event.target !== btnPopoverSelector) {
          btnPopoverSelector.classList.remove('is--expanded')
        }
      })
    })
  }, false)
}

const popoverButtons = document.querySelectorAll('[data-js-open-popover]')
initPopover(popoverButtons)
