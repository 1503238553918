const menu = document.querySelector('.menu')
const menuToggle = '[data-js-menu-toggle]'
const menuLevel1 = '[data-js-menu-level-1]'
const menuLevel1Item = '[data-js-menu-level-1-item]'
const menuToggleSelector = menu?.querySelectorAll(menuToggle)
const backButton = menu?.querySelectorAll('[data-js-menu-back]')
const menuLinks = menu?.querySelectorAll('[data-js-menu-link]')
const menuLinkFantissima = 'data-js-menu-link-fantissima'
const expandedClass = 'is--expanded'
const activeClass = 'is--active'

menuToggleSelector?.forEach(toggle => {
  toggle.addEventListener('click', function () {
    const expanded = this.classList.contains(expandedClass)

    if (expanded === false) {
      menuToggleSelector.forEach(toggle => {
        toggle.classList.remove(expandedClass)
      }, false)
      this.classList.add(expandedClass)
    } else {
      this.classList.remove(expandedClass)
    }
  }, false)
}, false)

backButton?.forEach(button => {
  button.addEventListener('click', function () {
    this
      .closest(menuLevel1)
      .querySelector('.' + expandedClass)
      .classList.remove(expandedClass)
  }, false)
}, false)

menuLinks?.forEach(link => {
  if (link.href === document.URL &&
      !document.querySelector('.' + expandedClass + menuToggle) &&
      !link.hasAttribute(menuLinkFantissima)) {
    link
      .classList
      .add(activeClass)
    if (link.closest(menuLevel1Item).querySelector(menuToggle)) {
      link
        .closest(menuLevel1Item)
        .querySelector(menuToggle)
        .classList
        .add(expandedClass)
    }
  }
}, false)
