const stageMedia = document.querySelector('.stage__media')

if (window.innerWidth > 1023 && stageMedia) {
  checkIfScrolled()
}

document.addEventListener('scroll', function () {
  if (window.innerWidth > 1023 && stageMedia) {
    checkIfScrolled()
  }
})

const body = document.querySelector('body')

if (body) {
  const bodyResizeObserver = new ResizeObserver(() => {
    if (window.innerWidth > 1023 && stageMedia) {
      checkIfScrolled()
    }
  })

  bodyResizeObserver.observe(body)
}

const stageText = document.querySelector('.stage .element')
const stageTextResizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    document.documentElement.style.setProperty(
      '--stage-text-height',
      entry.contentRect.bottom + entry.contentRect.top + 'px'
    )
  }
})

if (stageText) {
  stageTextResizeObserver.observe(stageText)
}

function checkIfScrolled () {
  const scrollTop = document.documentElement.scrollTop
  const stageHeight = stageMedia.offsetHeight - 400
  const body = document.querySelector('body')
  const isScrolledClass = 'is--scrolled'

  if (scrollTop > stageHeight) {
    body.classList.add(isScrolledClass)
  } else {
    body.classList.remove(isScrolledClass)
  }
}
