const cardSelectOptions = document.querySelectorAll('[data-js-select-card-option]')

cardSelectOptions
  .forEach(options => options.addEventListener('change', toggleSelectCardDescription))

function toggleSelectCardDescription (event) {
  const container = event.target.closest('[data-js-card-select]')
  const descriptions = container.querySelectorAll('[data-js-select-card-description]')
  const targetDescriptionId = event.target.getAttribute('aria-describedby')

  descriptions.forEach(entry => {
    entry.setAttribute('hidden', 'hidden')

    const entryId = entry.getAttribute('id')

    if (entryId === targetDescriptionId) {
      entry.removeAttribute('hidden')
    }
  })
}
