const disableFormFields = function () {
  const disabledFormWrapper = document.querySelector('[data-js-disable-form-fields]')

  if (disabledFormWrapper) {
    const formFields = Array.from(disabledFormWrapper.querySelectorAll('select, input, textarea'))

    formFields.forEach((formField) => {
      formField.setAttribute('disabled', 'disabled')
    })
  }
}
export const toggleFieldsets = function () {
  const fieldsetToggles = Array.from(document.querySelectorAll('[data-js-toggle-fieldset]'))

  fieldsetToggles.forEach((fieldsetToggle) => {
    const controls = document.querySelector('#' + fieldsetToggle.getAttribute('aria-controls'))
    const controlsFormFields = Array.from(controls.querySelectorAll('select, input, textarea'))
    const tag = fieldsetToggle.tagName.toLowerCase()
    let type = 'checkbox'
    let toggle = fieldsetToggle

    if (tag === 'input') {
      type = fieldsetToggle.getAttribute('type')
    } else if (tag === 'option') {
      type = 'select'
    } else if (tag === 'button') {
      type = 'button'
    }

    if (type === 'select') {
      toggle = fieldsetToggle.parentNode
    }

    function hideFieldsets () {
      controls.setAttribute('hidden', 'hidden')
    }

    function showFieldsets () {
      controls.removeAttribute('hidden')
    }

    function setRequired () {
      controlsFormFields.forEach((controlsFormField) => {
        if (controlsFormField.hasAttribute('data-js-set-required') && !controlsFormField.closest('[hidden]')) {
          controlsFormField.setAttribute('required', 'required')
        }
      })
    }

    function setDisabled () {
      controlsFormFields.forEach((controlsFormField) => {
        controlsFormField.setAttribute('disabled', 'disabled')
      })
    }

    function removeRequired () {
      controlsFormFields.forEach((controlsFormField) => {
        if (controlsFormField.hasAttribute('data-js-set-required')) {
          controlsFormField.removeAttribute('required')
        }
      })
    }

    function removeDisabled () {
      controlsFormFields.forEach((controlsFormField) => {
        controlsFormField.removeAttribute('disabled')
      })
    }

    if (((type === 'checkbox' || type === 'radio') && fieldsetToggle.checked) ||
        (type === 'select' && fieldsetToggle.selected) || (type === 'button' && fieldsetToggle.getAttribute('aria-pressed') === 'true')) {
      showFieldsets()
      setRequired()
      removeDisabled()
    }

    if (type === 'checkbox' || type === 'select') {
      toggle.addEventListener('change', function () {
        if (type === 'checkbox') {
          if (fieldsetToggle.checked) {
            showFieldsets()
            setRequired()
            removeDisabled()
          } else {
            hideFieldsets()
            removeRequired()
            setDisabled()
          }
        } else if (type === 'select') {
          if (fieldsetToggle.selected) {
            showFieldsets()
            setRequired()
            removeDisabled()
          } else {
            hideFieldsets()
            removeRequired()
            setDisabled()
          }
        }
      }, false)
    }

    if (type === 'radio') {
      const radioToggles = Array.from(fieldsetToggle.parentNode.parentNode.querySelectorAll('input[type="radio"]'))
      radioToggles.forEach((radio) => {
        radio.addEventListener('change', function () {
          if (fieldsetToggle.checked) {
            showFieldsets()
            setRequired()
            removeDisabled()
          } else {
            hideFieldsets()
            removeRequired()
            setDisabled()
          }
        }, false)
      })
    }

    if (type === 'button') {
      if (fieldsetToggle.hasAttribute('data-js-toggle-btn')) {
        const toggleGroup = fieldsetToggle.closest('[data-js-toggle-btn-group]')
        const toggleGroupObserver = new MutationObserver(() => {
          if (fieldsetToggle.getAttribute('aria-pressed') === 'true') {
            showFieldsets()
            setRequired()
            removeDisabled()
          } else {
            hideFieldsets()
            removeRequired()
            setDisabled()
          }
        })
        toggleGroupObserver.observe(toggleGroup, {
          subtree: true,
          childList: true
        })
      }
    }
  })
}

disableFormFields()
toggleFieldsets()
