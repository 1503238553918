export const toggleButtonGroups = () => {
  const toggleButtonGroups = document.querySelectorAll('[data-js-toggle-btn-group]')

  toggleButtonGroups.forEach(entry => {
    const toggleButtons = entry.querySelectorAll('[data-js-toggle-btn]')
    const pressedButtonClass = 'btn-primary--selected'
    const containerModifierClass = 'toggle--is-pressed'
    const buttonTextPressed = entry.getAttribute('data-js-toggle-btn-text-pressed')
    const buttonTextUnpressed = entry.getAttribute('data-js-toggle-btn-text-unpressed')

    toggleButtons.forEach(button => {
      button.addEventListener('click', function () {
        const toggleButtonContainer = this.closest('[data-js-toggle-btn-container]')

        toggleButtons.forEach(button => {
          const toggleButtonContainer = button.closest('[data-js-toggle-btn-container]')

          button.setAttribute('aria-pressed', false)
          button.querySelector('.btn-primary').classList.remove(pressedButtonClass)
          button.querySelector('.btn-primary').innerHTML = buttonTextUnpressed

          if (toggleButtonContainer) {
            toggleButtonContainer.classList.remove(containerModifierClass)
          }
        }, false)

        this.setAttribute('aria-pressed', true)
        this.querySelector('.btn-primary').classList.add(pressedButtonClass)
        this.querySelector('.btn-primary').innerHTML = buttonTextPressed

        if (toggleButtonContainer) {
          toggleButtonContainer.classList.add(containerModifierClass)
        }
      }, false)
    }, false)
  }, false)
}

toggleButtonGroups()
