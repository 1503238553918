const body = document.querySelector('body')
const masonries = document.querySelectorAll('[data-js-masonry]')
const masonryConfig = {
  tablet: {
    width: 768,
    rowsPerItem: 3,
    cols: 2,
    colOffsets: [1, 2]
  },
  desktop: {
    width: 1248,
    rowsPerItem: 6,
    cols: 3,
    colOffsets: [1, 3, 2]
  }
}

if (body) {
  const bodyResizeObserver = new ResizeObserver(() => {
    initMasonry()
  })

  bodyResizeObserver.observe(body)
}

function initMasonry () {
  let viewportConfig

  masonries.forEach(function (masonry, index) {
    if (window.innerWidth >= masonryConfig.desktop.width) {
      viewportConfig = masonryConfig.desktop
    } else if (window.innerWidth >= masonryConfig.tablet.width) {
      viewportConfig = masonryConfig.tablet
    } else {
      return
    }

    setGridAreas(masonry, viewportConfig.rowsPerItem, viewportConfig.cols, viewportConfig.colOffsets)
  })
}

function setGridAreas (masonry, rowsPerItem, cols, colOffsets) {
  const items = masonry.querySelectorAll('[data-js-masonry-grid-item]')
  let maxRows = 0

  items.forEach((item, index) => {
    const colIndex = index % cols
    const rowIndex = Math.floor(index / cols)

    const startCol = colIndex + 1
    const startRow = rowIndex * rowsPerItem + colOffsets[colIndex]

    const endCol = startCol + 1
    const endRow = startRow + rowsPerItem
    const rows = endRow - 1

    const gridArea = `${startRow}/${startCol}/${endRow}/${endCol}`
    item.style.setProperty('--masonry-item-grid-area', gridArea)

    if (rows > maxRows) {
      maxRows = rows
    }
  })

  if (window.innerWidth >= masonryConfig.desktop.width) {
    setMasonryOffset(masonry, maxRows)
  }
}

function setMasonryOffset (masonry, rows) {
  const masonryGridContainer = masonry.querySelector('[data-js-masonry-grid-container]')

  if (masonryGridContainer) {
    const masonryGridHeight = masonryGridContainer.offsetHeight
    const offset = (Math.floor(masonryGridHeight / rows) * 2)
    masonryGridContainer.style.setProperty('--masonry-offset', `-${offset}px`)
  }
}
