export const sectionMenu = function (button) {
  if (button) {
    handleButton(button)
  } else {
    const sectionMenuButtons = document.querySelectorAll('[data-js-open-section-menu]')

    sectionMenuButtons.forEach(function (sectionMenuButton) {
      handleButton(sectionMenuButton)
    })
  }

  function handleButton (sectionMenuButton) {
    sectionMenuButton.addEventListener('click', setAriaState, false)

    document.addEventListener('click', function (event) {
      if (event.target !== sectionMenuButton) {
        sectionMenuButton.setAttribute('aria-expanded', 'false')
      }
    })
  }

  function setAriaState () {
    const expanded = this.getAttribute('aria-expanded')

    if (expanded === 'false') {
      this.setAttribute('aria-expanded', true)
    } else {
      this.setAttribute('aria-expanded', false)
    }
  }
}
