import { closeModal, showModal } from './modal'

export const initExitIntentPopup = function () {
  const popup = document.querySelector('[data-js-exit-intent-popup]')

  if (popup && typeof popup.close === 'function' && typeof popup.showModal === 'function') {
    const basket = document.querySelector('.cart-icon__badge')
    if (basket && basket.dataset.actionTicket === '1') {
      const exit = function (event) {
        const shouldExit =
                    event.target === popup // user clicks on backdrop of the dialog

        if (shouldExit) {
          closeModal(popup)
        }
      }

      const mouseEvent = function (event) {
        const shouldShowExitIntent =
                    !event.toElement &&
                    !event.relatedTarget &&
                    event.clientY < 10

        if (shouldShowExitIntent) {
          document.removeEventListener('mouseout', mouseEvent)
          showModal(popup)
        }
      }

      setTimeout(() => {
        document.addEventListener('mouseout', mouseEvent)
        popup.addEventListener('click', exit)
      }, 2000)

      window.addEventListener('popstate', function () {
        showModal(popup)
      })

      document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
          setTimeout(function () {
            if (document.hidden) {
              showModal(popup)
            }
          }, 1000)
        }
      })

      history.pushState(null, null, window.location.href)
    }
  }
}
