function checkBrowser () {
  if (
    supportsResizeObserver() === false ||
    supportDialogElement() === false
  ) {
    document.querySelector('[data-js-browser-not-supported]').removeAttribute('hidden')
  }

  // require ff 69, chrome 64, safari 14.1
  function supportsResizeObserver () {
    return 'ResizeObserver' in window
  }

  // require ff 98, chrome 37, safari 15.4
  function supportDialogElement () {
    return !!document.createElement('dialog').showModal
  }
}

checkBrowser()
