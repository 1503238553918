export const initCookieLayer = function () {
  const showCookieLayerButton = document.querySelector('[data-js-show-cookie-layer]')

  if (showCookieLayerButton) {
    showCookieLayerButton.addEventListener('click', function () {
      if (window.CCM) {
        window.CCM.openWidget()
      }
    })
  }
}
