const observer = new IntersectionObserver(callback)
const element = document.querySelectorAll('[data-js-observe-visibility]')
const options = { threshold: [0, 0.25, 0.5, 0.75, 1] }

function callback (entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is--visible')
    } else {
      entry.target.classList.remove('is--visible')
    }
  }, options)
}

if (element.length) {
  element.forEach(el => {
    observer.observe(el)
  }, false)
}
