export const initPromotionBanner = function () {
  const promotionBanner = document.querySelector('[data-js-promotion-banner]')
  const promotionBannerClose = document.querySelector('[data-js-promotion-banner-close]')
  const promotionBannerActiveClass = 'is--active'

  const isLocalStorageEnabled = () => {
    try {
      const key = '__storage__test'
      window.localStorage.setItem(key, null)
      window.localStorage.removeItem(key)
      return true
    } catch (e) {
      return false
    }
  }

  if (promotionBanner) {
    if ('ResizeObserver' in window) {
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          document.documentElement.style.setProperty(
            '--promotion-banner-height',
            entry.contentRect.bottom + entry.contentRect.top + 'px'
          )
        }
      }).observe(promotionBanner)
    }

    if (isLocalStorageEnabled()) {
      const promotionBannerClosedTimestamp = localStorage.getItem('promotionBannerClosedTimestamp')
      if (promotionBannerClosedTimestamp) {
        const now = new Date().getTime()
        const timeElapsed = now - promotionBannerClosedTimestamp
        const oneDay = 24 * 60 * 60 * 1000

        if (timeElapsed < oneDay) {
          promotionBanner.classList.remove(promotionBannerActiveClass)
        } else {
          localStorage.removeItem('promotionBannerClosedTimestamp')
          promotionBanner.classList.add(promotionBannerActiveClass)
        }
      } else {
        promotionBanner.classList.add(promotionBannerActiveClass)
      }

      promotionBannerClose.addEventListener('click', function () {
        promotionBanner.classList.remove(promotionBannerActiveClass)
        localStorage.setItem('promotionBannerClosedTimestamp', new Date().getTime())
      })
    } else {
      promotionBanner.classList.add(promotionBannerActiveClass)
    }
  }
}
